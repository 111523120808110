import Cookies from 'js-cookie'

window.addEventListener("DOMContentLoaded", loadCookiesPopup)
window.addEventListener("turbolinks:load", loadCookiesPopup)

function loadCookiesPopup() {
  var popup = document.querySelector(".js-cookies-popup");

  var allowCookies = Cookies.get("allow_cookies");

  if (!allowCookies && popup) {
    popup.style.display = 'block';
  }

  var button = document.querySelector(".js-cookies-accept");

  if (!button) return;

  button.addEventListener("click", function (event) {
    if (popup) {
      Cookies.set("allow_cookies", "true", { expires: 365 });
      popup.remove();
    }
  });
};
