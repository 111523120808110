import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  static values = {
    path: String,
  };

  connect() {
    this.origin = new URL(window.location).origin;
  }

  update(event) {
    this.url = new URL(this.origin);
    this.url.pathname = this.pathValue + event.target.value;
    this.buttonTarget.href = this.url;
  }

  handleKey(event) {
    const isEnter = event.key === "Enter";
    const canClick = this.buttonTarget.dataset.disabled !== "true";
    if (isEnter) event.preventDefault();
    if (isEnter && canClick) this.buttonTarget.click();
  }
}
