window.addEventListener("DOMContentLoaded", loadModals);
window.addEventListener("turbolinks:load", loadModals);

function loadModals() {
  const buttons = document.querySelectorAll("[data-toggle]");

  for(const button of buttons) {
    const modal_id = button.getAttribute("data-toggle");
    const modal = document.getElementById(modal_id);

    button.addEventListener("click", function (event) {
      modal.classList.add("oog-video-modal--open");
      modal.style = ""; // Remove initial display: none to prevent loading the video

      modal.addEventListener("click", function (event) {
        modal.classList.remove("oog-video-modal--open");

        const iframe = modal.querySelector("iframe");

        if (iframe) {
          const src = iframe.src;

          iframe.src = "";
          iframe.src = src;
        }
      });
    });
  }
};
