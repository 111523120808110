import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["disabled"];
  static targets = ["field", "action"];

  connect() {
    this.update = this.update.bind(this);
    this.fieldTargets.forEach((el) =>
      el.addEventListener("input", this.update)
    );
    this.update();
  }

  disconnect() {
    this.fieldTargets.forEach((el) =>
      el.removeEventListener("input", this.update)
    );
  }

  update() {
    const atLeastOneEmpty = Array.from(this.fieldTargets).some(
      (el) => el.value.trim() == ""
    );
    this.actionTarget.classList.toggle(this.disabledClass, atLeastOneEmpty);
  }
}
