import '@splidejs/splide/dist/js/splide.min.js'

window.addEventListener("DOMContentLoaded", loadSplide);
window.addEventListener("turbolinks:load", loadSplide);

function loadSplide() {
  const options = {
    type: "loop",
    pagination: false,
    padding: {
      right: "35rem",
      left: "35rem",
    },
    gap: "5rem",
    breakpoints: {
      1500: {
        gap: "4rem",
        padding: {
          right: "20rem",
          left: "20rem",
        },
      },
      1000: {
        gap: "3rem",
        padding: {
          right: "14rem",
          left: "14rem",
        },
      },
      700: {
        gap: "2rem",
        padding: {
          right: "9rem",
          left: "9rem",
        },
      },
      500: {
        gap: "2rem",
        padding: {
          right: "6rem",
          left: "6rem",
        },
      },
    },
  };

  const className = ".js-splide-mount";
  const splides = document.querySelectorAll(className);

  for(const splideElement of splides) {
    new Splide(splideElement, options).mount();
  }
}
