document.addEventListener("turbolinks:load", platformDetection);

function platformDetection() {
  // Target some UAs with specific CTAs such as app store downloads
  var isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent) || (navigator.vendor.indexOf("Apple") > -1 && "ontouchstart" in document.documentElement);
  var isAndroid = /Android/g.test(navigator.userAgent);

  if (isIOS) {
    document.documentElement.classList.add('is-ios');
  }

  if (isAndroid) {
    document.documentElement.classList.add('is-android');
  }

  if (isIOS || isAndroid) {
    document.documentElement.classList.add('has-app');
  } else {
    document.documentElement.classList.add('no-app');
  }
}
