import { Controller } from "@hotwired/stimulus";
import { post, get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["modal", "downloadButton", "submit", "textarea"];

  static values = {
    url: String,
    groupId: String,
    playerId: String,
  };

  connect() {
    this.submitTarget.addEventListener("click", () => {
      const body = {
        group_id: this.groupIdValue,
        player_id: this.playerIdValue,
        text: this.textareaTarget.value,
      };
      post(this.urlValue, { body: JSON.stringify(body) })
        .then((response) => response.json)
        .then((responseBody) => {
          this.pollBackgroundRequest(responseBody.background_request_path);
        });

      this.modalTarget.showModal();
    });

    this.modalTarget.addEventListener("open.zf.reveal", () => {
      this.downloadButtonTarget.disabled = true;
    });
  }

  closeModal() {
    this.modalTarget.close();
  }

  download(e) {
    e.preventDefault();
    window.open(this.downloadUrl, "_blank");
    this.modalTarget.close();
  }

  disconnect() {
    clearInterval(this.pollingTimeout);
  }

  pollBackgroundRequest(backgroundRequestPath) {
    clearInterval(this.pollingTimeout);

    this.pollingInterval = setInterval(() => {
      get(backgroundRequestPath)
        .then((response) => response.json)
        .then((response) => {
          if (response.background_request.complete !== "t") return;

          const data = response.background_request.result;

          this.downloadUrl = data.url;
          this.downloadButtonTarget.disabled = false;
          clearInterval(this.pollingInterval);
        })
        .catch((e) => {
          clearInterval(this.pollingInterval);
        });
    }, 2000);
  }
}
