import $ from 'jquery'

/*
 * Workaround to fix the z-index of the logo that hovers the top-bar
 * and the floating menus in the top-bar
 */

document.addEventListener("turbolinks:load", loadTopbar);

function loadTopbar() {
  var $topbar = $(".js-topbar");
  var activeMenuClass = "has-active-menu";
  var $menuButtons = $(".js-dropdown-close-hack");

  // This is the semi-transparent overlay that blocks out the rest of the page
  // when the menu is open
  var $overlay = $('<div class="oog-topbar__overlay" />')

  $topbar.on("show.zf.dropdownMenu", function () {
    $topbar.toggleClass(activeMenuClass, true);
    $overlay.appendTo($topbar);
  });

  $topbar.on("hide.zf.dropdownMenu", function () {
    $topbar.toggleClass(activeMenuClass, false);
    $overlay.remove();
  });

  $menuButtons.on("touchend", function (event) {
    var $el = $(event.target);
    var hackClass = "is-active-hack";

    $el.toggleClass(hackClass);

    setTimeout(function () {
      if ($el.parent("li").hasClass("is-active") && !$el.hasClass(hackClass)) {
        $("body").click();
      }
    }, 50);
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 140) {
      $topbar.addClass('is-stuck');
    } else {
      $topbar.removeClass('is-stuck');
    }
  });
};
