import $ from "jquery";
import "foundation-sites";

import jqueryUjsInit from "jquery-ujs";
jqueryUjsInit($);

import Turbolinks from "turbolinks";
Turbolinks.start();

const afterLoadHooks = new Map();

function registerAfterLoadHook(id, callback) {
  afterLoadHooks.set(id, callback);
}

window.registerAfterLoadHook = registerAfterLoadHook;

document.addEventListener("turbolinks:load", () => {
  window.scrollTo(0, 0);

  afterLoadHooks.forEach((callback) => {
    try {
      callback();
    } catch (e) {
      console.error(e);
    }
  });

  $(document).foundation();
});

import "../modernizr";
import "./platform_detection";
import "./topbar";
import "./splide";
import "./modals";
import "./cookies";

import { Application } from "@hotwired/stimulus";
import ChangeAccountDetailsController from "./controllers/change_account_details_controller";
import GenerateParentLettersController from "./controllers/generate_parent_letters_controller";
import ClassCodeController from "./controllers/class_code_controller";
import EmptyController from "./controllers/empty_controller";
import ValidController from "./controllers/valid_controller";

window.Stimulus = Application.start();
Stimulus.register("change-account-details", ChangeAccountDetailsController);
Stimulus.register("generate-parent-letters", GenerateParentLettersController);
Stimulus.register("class-code", ClassCodeController);
Stimulus.register("empty", EmptyController);
Stimulus.register("valid", ValidController);
