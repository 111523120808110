import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["disabled"];
  static targets = ["field", "action"];

  connect() {
    this.update = this.update.bind(this);
    this.fieldTargets.forEach((el) =>
      el.addEventListener("input", this.update)
    );
    this.update();
  }

  disconnect() {
    this.fieldTargets.forEach((el) =>
      el.removeEventListener("input", this.update)
    );
  }

  update() {
    const atLeastOneInvalid = !Array.from(this.fieldTargets).every(
      (el) => el.checkValidity() && el.value != ""
    );
    this.actionTarget.dataset.disabled = atLeastOneInvalid;
    this.actionTarget.classList.toggle(this.disabledClass, atLeastOneInvalid);
  }
}
